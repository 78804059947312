var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          attrs: {
            value: _vm.userId,
            clearable: "",
            placeholder: "请选择客户",
            remote: "",
            filterable: "",
            "reserve-keyword": "",
            "remote-method": _vm.getRegionSelection,
            loading: _vm.loading
          },
          on: {
            change: function($event) {
              return _vm.$emit("update:userId", $event)
            }
          }
        },
        _vm._l(_vm.selections, function(item) {
          return _c(
            "el-option",
            { key: item.ID, attrs: { label: item.Name, value: item.ID } },
            [
              item.name != ""
                ? _c("span", [
                    _vm._v("【" + _vm._s(item.ID) + "】" + _vm._s(item.phone))
                  ])
                : _c("span", [
                    _vm._v("【" + _vm._s(item.ID) + "】" + _vm._s(item.phone))
                  ])
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }